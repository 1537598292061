import { capitalize } from 'lodash';
import React from 'react';
import { useGetCompanyById } from '../../../../../../../../../../../../services/go-api/company/company';
import './styles.css';

interface ZoneCardModalCompanyOrderProps {
  id: string;
  order: number;
}

export const ZoneCardModalCompanyOrder = ({
  id,
  order,
}: ZoneCardModalCompanyOrderProps) => {
  const { data: company } = useGetCompanyById(id, {
    query: { enabled: !!id },
  });

  return (
    <div className='zone-card-modal-company-summary-container'>
      <div>{capitalize(company?.name?.toLowerCase())}</div>
      <div className='zone-card-modal-company-summary-container-order'>
        {order}
      </div>
    </div>
  );
};

export default ZoneCardModalCompanyOrder;
