import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddButton from '../../../../../../components/add-button';
import Header from '../../../../../../components/header';
import { useGetAllZones } from '../../../../../../services/go-api/zone/zone';
import './styles.css';
import MenuAction from '../../../../components/menu-action';
import MenuListContainer from '../../../../components/menu-list-container';
import PageContainer from '../../../../components/page-container';
import Search from '../../../../components/search';
import ZoneCard from './components/zone-card';

const ZoneList = () => {
  // Attributes
  const [search, setSearch] = useState('');
  const query = useGetAllZones({
    q: search,
  });
  const history = useHistory();

  return (
    <PageContainer>
      <Header title='Our zones' />
      <MenuListContainer>
        <MenuAction>
          <Search
            value={search}
            placeholder='Search for a zone'
            onChange={setSearch}
          />
          <AddButton
            title='Add new zone'
            handleClick={() => history.push('/dashboard/zones/details')}
          />
        </MenuAction>
        <div className='card-group-zone-container'>
          {query?.data?.data &&
            query.data?.data?.map((zone) => <ZoneCard zone={zone} />)}
        </div>
      </MenuListContainer>
    </PageContainer>
  );
};

export default ZoneList;
