export interface OrdersListHeaderProps {
	timeScale: TimeScale;
	setTimeScale: React.Dispatch<React.SetStateAction<TimeScale>>;
	totalOrders: number
}

export enum TimeScale {
	Hour = 0,
	Day,
	Week,
	Month,
	All
}
