import React from 'react';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import { OrderTableProps } from './props';
import './style.css';

const OrderTable = ({ basketProducts }: OrderTableProps) => {
  //Attributes
  const { translate } = useTranslation();

  //Render
  return (
    <table className='order-table'>
      <tr>
        <th>{translate('restaurant')}</th>
        <th>{translate('items')}</th>
        <th>{translate('amount')}</th>
        <th>{translate('price')}</th>
      </tr>
      {basketProducts?.map((basketProduct) => (
        <tr>
          <td>{basketProduct?.product?.restaurant?.name}</td>
          <td style={{ maxWidth: '210px' }}>{basketProduct?.product?.name}</td>
          <td>{basketProduct?.quantity}</td>
          <td>{`${basketProduct?.totalAmount}€`}</td>
        </tr>
      ))}
    </table>
  );
};

export default OrderTable;
