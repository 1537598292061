import React from "react";
import { Route, Switch } from "react-router-dom";
import OrdersDetail from "./modules/detail";
import OrdersList from "./modules/list";

const Orders = () => {
	return (
		<Switch>
			<Route path="/dashboard/new-orders/:id" component={OrdersDetail} />
			<Route path="/dashboard/new-orders" component={OrdersList} />
		</Switch>
	);
};

export default Orders;
