import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import { OrdersListHeaderProps, TimeScale } from './props';
import './style.css';

const OrdersListHeader = ({
  timeScale,
  setTimeScale,
  totalOrders,
}: OrdersListHeaderProps) => {
  // Attributes
  const { translate } = useTranslation();
  const history = useHistory();

  // Functions
  const handleClick = useCallback((time_scale: TimeScale) => {
    setTimeScale(time_scale);
    history.replace(
      `/dashboard/orders?type=${TimeScale[time_scale].toLowerCase()}`
    );
  }, []);

  // Render
  return (
    <div className='orders-header-container'>
      <div className='orders-header-title'>
        <div>{translate('orders')}</div>
        <div className='orders-header-title-total-orders'>{`(${totalOrders})`}</div>
      </div>
      <div className='orders-time-scale-container'>
        {Object.entries(TimeScale)
          .filter((time) => Number.isNaN(Number.parseInt(time[0])))
          .map((time) => (
            <div
              className={`orders-time-scale-container-container ${
                time[1] === timeScale ? 'selected' : ''
              }`}
              key={`time_scale_${time[1]}`}
              onClick={() => handleClick(time[1] as TimeScale)}
            >
              <div
                className='orders-time-scale-container-text'
                style={
                  time[1] === timeScale
                    ? { color: '#061A42' }
                    : { color: 'rgba(6, 26, 66, 0.25)' }
                }
              >
                {translate(`time-scale-${time[0]}`)}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrdersListHeader;
