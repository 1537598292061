import React from 'react';
import { useGetCompanyById } from '../../../../../../../../../../services/go-api/company/company';
import { ZoneCompanyTagProps } from './props';
import './styles.css';
import capitalize from 'lodash/capitalize';

const ZoneCompanyTag = ({ id }: ZoneCompanyTagProps) => {
  // Attributes
  const { data: company } = useGetCompanyById(id, {
    query: { enabled: !!id },
  });
  const formatCompanyName = capitalize(company?.name?.toLowerCase());

  if (!company) {
    return null;
  }

  return <div className='zone-company-tag'>{formatCompanyName}</div>;
};

export default ZoneCompanyTag;
